import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AbstractLoadableGuard } from '../../shared/utils/abstract-loadable.guard'
import { getProgressDataLoaded } from '../store/jobsite-progress/jobsite-progress.selectors'
import { JobsiteProgressActions } from '../store/jobsite-progress/jobsite-progress.actions'
import { State } from '../store'
import { getTechnique } from '../store/router/router.selectors'
import { map } from 'rxjs/operators'

@Injectable()
export class ProgressDwallGuard extends AbstractLoadableGuard<State> {
  constructor(store: Store<State>) {
    super(
      store,
      getProgressDataLoaded,
      JobsiteProgressActions.getProgressData,
      true,
      {
        preCheck: () =>
          this.store
            .select(getTechnique)
            .pipe(map(technique => technique === 'HF')),
      },
    )
  }
}
