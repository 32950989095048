// Build information, automatically generated by `ng-info`
const build = {
    version: "21.6.0-SNAPSHOT",
    timestamp: "Fri Jan 24 2025 11:40:18 GMT+0100 (Central European Standard Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "50d3bf",
        fullHash: "50d3bf4d8e11b2366108fee471705aa025050e59"
    }
};

export default build;